<template>
  <en-dialog :model-value="modelValue" width="80%" title="服务列表" @close="$emit('update:model-value', false)">
    <template #operation>
      <en-button type="primary" link @click="operation.sms.click">发送短信</en-button>
      <en-button type="primary" link @click="operation.coupon.click">发送优惠券</en-button>
      <button-download
        :ajax="{ action: 'GET /enocloud/business/opportunity/export', params: (params) => (params.payload = table.$ajaxParams.payload) }"
      >
        导出
      </button-download>
    </template>

    <table-dynamic
      :height="600"
      code="BNOPFLD"
      :data="table.data"
      :loading="table.loading"
      :paging="table.paging"
      :method="table.get"
      pagination
      :config="table.config"
      :addition="{ branchIds: store.user.branch?.id ? [store.user.branch?.id] : [] }"
      show-selectable
      @selection-change="table.selection.change"
    ></table-dynamic>
  </en-dialog>

  <send-coupon
    v-model="coupon.visible"
    :options="table.selection.data"
    :props="{ name: 'customerName', id: 'customerId' }"
    @confirm="table.get"
  ></send-coupon>

  <short-message-template
    v-model="sms.visible"
    :data="table.selection.data"
    :props="{ name: 'customerName', cellphone: 'customerCellphone' }"
  ></short-message-template>
</template>

<script lang="ts">
import { EnMessage, EnMessageBox } from '@enocloud/components'
import { SendCoupon, ShortMessageTemplate } from '@enocloud/business'

export default factory({
  components: { SendCoupon, ShortMessageTemplate },

  props: {
    modelValue: Boolean
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean'
  },

  config: {
    children: {
      operation: {
        coupon: {
          click() {
            if (!this.table.selection.data.length) return EnMessage.warning('请选择客户')
            this.coupon.visible = true
          }
        },
        sms: {
          click() {
            if (!this.table.selection.data.length) return EnMessage.warning('请选择客户')
            this.sms.visible = true
          }
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/business/opportunity/query',
            data: 'array',
            loading: true,
            pagination: true
          }
        },
        config: {
          OPERATION: { visible: false },
          VEHICLE_SPEC: {
            header: { filter: { type: 'text', field: 'vehicleSpec' } }
          },
          VEHICLE_LEAVE_DAYS: {
            header: { filter: { type: 'date', field: ['vehicleLeaveDaysStart', 'vehicleLeaveDaysEnd'], prop: { type: 'daterange' } } }
          }
        },
        children: {
          selection: {
            data: [] as EnocloudServiceDefinitions['ServiceReturnVisitQueryDto'][],
            change(rows: EnocloudServiceDefinitions['ServiceReturnVisitQueryDto'][]) {
              this.table.selection.data = rows
            }
          }
        }
      },
      coupon: {
        visible: false
      },
      sms: {
        visible: false
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
